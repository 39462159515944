.App {
    font-family: sans-serif;
    text-align: center;
}

.form {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 30px;
    margin-top: 10px;
}

.textField {
    margin-top: 10px !important;
}